<template>
  <v-dialog max-width="471" :value="true">
    <v-card>
      <v-card-text
        class="df-flex-xl df-flex-col align-center payment-approved-modal"
      >
        <font-awesome-icon
          class="modal__icon"
          color="#39AF49"
          icon="check-circle"
        />
        <v-btn class="modal__close-btn" icon @click="closeModal">
          <v-icon small> mdi-close </v-icon>
        </v-btn>
        <div class="df-flex-sm df-flex-col modal__text">
          <h1>Parabéns! Seu pagamento foi aprovado.</h1>
          <p>
            Bem vindo(a) ao plano
            <span>{{ planInfos[planPriority].name }}</span
            >!
            {{ planInfos[planPriority].approved_payment_message }}
          </p>
        </div>
        <df-button width="100%" @click="redirect">Continuar</df-button>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import planLevels from '@/modules/plans/data/planLevels'
import { planInfos } from '@/modules/payment/data/planPt'

export default {
  name: 'PaymentApprovedModal',

  components: {
    DfButton,
  },

  props: {
    planPriority: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      planInfos,
    }
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
    redirect() {
      if (this.planPriority == planLevels.light) {
        this.$router.push({
          path: '/catalog',
        })
      } else {
        this.$router.push({
          path: '/home',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.payment-approved-modal {
  padding: 32px 24px !important;

  .modal__icon {
    height: 40px;
    width: 40px;
  }
  .modal__close-btn {
    position: absolute;
    top: 20px;
    right: 10px;
  }
  .modal__text {
    text-align: center;
    font-family: 'Rubik';
    font-style: normal;

    h1 {
      color: #1a2b46;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
    p {
      color: #5b6459;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }
    span {
      text-transform: capitalize;
    }
  }
}
</style>
