<template>
  <div class="payment-home">
    <div class="df-flex-xl df-flex-col home__payment-infos">
      <div class="df-flex-l df-flex-col">
        <df-button
          class="secondary-button"
          left-icon="chevron-left"
          variant="text-secondary"
          width="65"
          @click="goToPreviousPage"
        >
          Voltar
        </df-button>
        <div class="df-flex-sm df-flex-col payment__header">
          <h1>Pagamento</h1>
          <p>Preencha as informações abaixo para concluir o pagamento</p>
        </div>
      </div>
      <payment-form />
    </div>
    <purchase-summary />
  </div>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import PaymentForm from '@/modules/payment/components/PaymentForm.vue'
import PurchaseSummary from '@/modules/payment/components/PurchaseSummary.vue'

export default {
  name: 'PaymentHome',

  components: {
    DfButton,
    PaymentForm,
    PurchaseSummary,
  },

  methods: {
    goToPreviousPage() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.payment-home {
  max-width: 1488px;
  margin: 0px auto;
  padding: 24px;
  position: relative;

  @include d(t) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  @include d(m) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .home__payment-infos {
    max-width: calc(100% - 432px);

    @include d(t) {
      max-width: 472px;
    }
    @include d(m) {
      max-width: 472px;
    }
    .payment__header {
      h1 {
        color: #1a2b46;
        font-family: 'Rubik';
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }
      p {
        color: #5b6459;
        font-family: 'Rubik';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}
::v-deep .secondary-button .v-btn__content {
  color: #39af49;
}
</style>
