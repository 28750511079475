<template>
  <v-form ref="paymentForm" class="df-flex-xl df-flex-col payment-form">
    <div class="df-flex-xl df-flex-col">
      <df-input
        v-model="email"
        label="E-mail"
        placeholder="Insira o e-mail de cobrança"
        :max-length="121"
        :rules="[required, validEmail, maxLength(email, 120)]"
      />
      <df-input
        v-model="userName"
        label="Nome"
        placeholder="Insira seu nome"
        :max-length="121"
        :rules="[required, text, maxLength(userName, 120)]"
      />
      <df-input
        v-model="documentNumber"
        label="CPF"
        placeholder="Insira seu CPF"
        v-mask="'###.###.###-##'"
        :rules="[required, validCPF]"
      />
    </div>
    <v-divider />
    <div class="df-flex-xl df-flex-col">
      <div class="df-flex-xl">
        <div class="df-col-6 df-col-12-m">
          <df-input
            v-model="zipcode"
            label="CEP"
            placeholder="Insira o CEP"
            v-mask="'#####-###'"
            :rules="[required]"
            @input="getAddress"
          />
        </div>
        <div class="df-col-6 df-col-12-m">
          <df-input
            v-model="neighborhood"
            label="Bairro"
            placeholder="Insira o bairro"
            :loading="loadingZipcode"
            :max-length="121"
            :rules="[required, maxLength(neighborhood, 120)]"
          />
        </div>
      </div>
      <df-input
        v-model="city"
        label="Cidade"
        placeholder="Insira a cidade"
        :loading="loadingZipcode"
        :max-length="121"
        :rules="[required, text, maxLength(city, 120)]"
      />
      <div class="df-flex-xl">
        <div class="df-col-6 df-col-12-m">
          <df-input
            v-model="street"
            label="Rua"
            placeholder="Insira a rua"
            :loading="loadingZipcode"
            :max-length="121"
            :rules="[required, text, maxLength(city, 120)]"
          />
        </div>
        <div class="df-col-6 df-col-12-m">
          <df-input
            v-model="streetNumber"
            label="Número"
            placeholder="Insira o número da casa"
            type="number"
            :rules="[required, maxLength(streetNumber, 10)]"
          />
        </div>
      </div>
    </div>
    <div class="df-flex-xl df-flex-col">
      <div class="df-flex-l df-flex-col">
        <h2>Método de pagamento</h2>
        <div v-if="loadingPaymentMethods" class="df-flex-l df-flex-col">
          <v-skeleton-loader
            v-for="(_, index) in vindiPaymentMethods.length || 1"
            :key="index"
            height="70px"
            type="image"
            width="143px"
          />
        </div>
        <div
          v-for="payment in vindiPaymentMethods"
          :key="payment.id"
          class="df-flex-m df-flex-col card__tag"
          @click="selectPaymentMethod(payment)"
        >
          <font-awesome-icon
            v-if="shouldShowIcon(payment.code)"
            color="#5B6459"
            icon="credit-card"
          />
          <p>{{ payment.name }}</p>
        </div>
      </div>
      <df-input
        v-model="cardName"
        label="Nome no cartão"
        placeholder="Nome impresso no cartão"
        :max-length="121"
        :rules="[required, text, maxLength(cardName, 120)]"
      />
      <df-input
        v-model="cardNumber"
        label="Número do cartão"
        placeholder="Número do cartão"
        v-mask="'#### #### #### ####'"
        :rules="[required, validCardNumber]"
      />
      <div class="df-flex-xl">
        <div class="df-col-6">
          <df-input
            v-model="expirationDate"
            label="Data de expiração"
            placeholder="MM/YYYY"
            v-mask="'##/####'"
            :rules="[required, validCardExpirationDate]"
          />
        </div>
        <div class="df-col-6">
          <df-input
            v-model="cvc"
            label="CVC"
            type="number"
            placeholder="CVC"
            :rules="[required, maxLength(cvc, 3)]"
          />
        </div>
      </div>
    </div>
  </v-form>
</template>

<script>
import DfInput from '@/lib/components/Input/DfInput.vue'
import viacepAPI from '@/services/viacepAPI'
import paymentService from '@/modules/payment/services/paymentService'
import {
  maxLength,
  text,
  validCardExpirationDate,
  validCardNumber,
  validCPF,
  validEmail,
  required,
} from '@/utils/formValidations'
import { EventBus } from '@/utils/eventBus'

export default {
  name: 'PaymentForm',

  components: {
    DfInput,
  },

  data() {
    return {
      cardName: '',
      cardNumber: '',
      city: '',
      cvc: null,
      documentNumber: '',
      email: '',
      expirationDate: '',
      loadingZipcode: false,
      maxLength,
      neighborhood: '',
      loadingPaymentMethods: false,
      paymentMethod: '',
      state: '',
      street: '',
      required,
      streetNumber: null,
      text,
      userName: '',
      validCardExpirationDate,
      validCardNumber,
      validCPF,
      validEmail,
      vindiPaymentMethods: [],
      zipcode: '',
    }
  },

  async created() {
    this.loadingPaymentMethods = true
    try {
      const { data } = await paymentService.getVindiPaymentMethods()
      const filteredPaymentMethods = data.data.filter(
        (payment) => payment.status === 'active' && payment.code !== 'bank_slip'
      )
      this.vindiPaymentMethods = filteredPaymentMethods
      this.paymentMethod = 'credit_card'
    } catch (error) {
      console.error(error)
    } finally {
      this.loadingPaymentMethods = false
      EventBus.$on('requestData', this.sendData)
    }
  },

  beforeDestroy() {
    EventBus.$off('requestData', this.sendData)
  },

  methods: {
    async getAddress(zipcode) {
      zipcode = zipcode.replace(/\D/g, '')
      if (zipcode.length !== 8) return
      this.loadingZipcode = true
      try {
        const { data } = await viacepAPI.getAddress(zipcode)
        this.street = data.logradouro
        this.state = data.uf
        this.neighborhood = data.bairro
        this.city = data.localidade
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingZipcode = false
      }
    },
    selectPaymentMethod(payment) {
      this.paymentMethod = payment.code
    },
    sendData() {
      const params = {
        customerData: {
          name: this.userName,
          email: this.email,
          document_number: this.documentNumber.replace(/[.-]/g, ''),
          address: {
            street: this.street,
            number: this.streetNumber,
            zipcode: this.zipcode.replace(/[-]/g, ''),
            neighborhood: this.neighborhood,
            city: this.city,
            state: this.state,
            country: 'BR',
          },
        },
        paymentData: {
          card_number: this.cardNumber.replace(/\s/g, ''),
          card_cvv: this.cvc,
          card_expiration: this.expirationDate,
          holder_name: this.cardName,
          payment_method_code: this.paymentMethod,
          registry_code: this.documentNumber.replace(/[.-]/g, ''),
        },
      }
      EventBus.$emit('dataSent', params)
      EventBus.$emit('paymentForm', this.$refs.paymentForm)
    },
    shouldShowIcon(code) {
      return code === 'credit_card'
    },
  },
}
</script>

<style lang="scss" scoped>
.payment-form {
  .card__tag {
    width: fit-content;
    border-radius: 4px;
    border: 1px solid #39af49;
    padding: 12px;
    cursor: pointer;

    p {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
}
</style>
