import i18n from '@/language'

export function required(value) {
  return !!value || value === false || i18n.t('campo_obrigatorio')
}

export function validCPF(value) {
  const cleanedCPF = value.replace(/\D/g, '')
  if (cleanedCPF.length !== 11)
    return i18n.t('validations.document_number_length')
  let sum = 0
  let remainder
  if (
    cleanedCPF == '00000000000' ||
    cleanedCPF == '11111111111' ||
    cleanedCPF == '22222222222' ||
    cleanedCPF == '33333333333' ||
    cleanedCPF == '44444444444' ||
    cleanedCPF == '55555555555' ||
    cleanedCPF == '66666666666' ||
    cleanedCPF == '77777777777' ||
    cleanedCPF == '88888888888' ||
    cleanedCPF == '99999999999'
  ) {
    return i18n.t('validations.invalid_document_number')
  }
  for (let i = 1; i <= 9; i++)
    sum = sum + parseInt(cleanedCPF.substring(i - 1, i)) * (11 - i)
  remainder = (sum * 10) % 11
  if (remainder == 10 || remainder == 11) remainder = 0
  if (remainder != parseInt(cleanedCPF.substring(9, 10)))
    return i18n.t('validations.invalid_document_number')
  sum = 0
  for (let i = 1; i <= 10; i++)
    sum = sum + parseInt(cleanedCPF.substring(i - 1, i)) * (12 - i)
  remainder = (sum * 10) % 11
  if (remainder == 10 || remainder == 11) remainder = 0
  if (remainder != parseInt(cleanedCPF.substring(10, 11)))
    return i18n.t('validations.invalid_document_number')
  return true
}

export function validEmail(value) {
  return /.+@.+\..+/.test(value) || i18n.t('validations.invalid_email')
}

export function maxLength(value, maxLength) {
  if (typeof value === 'number') value = String(value)
  if (value?.length > maxLength)
    return i18n.t('validations.max_length', [maxLength])
  return true
}

export function text(value) {
  if (/^[a-zA-Zà-úÀ-ÚçÇ\s]+$/.test(value)) return true
  return i18n.t('validations.text_only')
}

export function validCardNumber(value) {
  const cleanedCardNumber = value.replace(/\D/g, '')
  if (cleanedCardNumber.length < 14)
    return i18n.t('validations.card_number_length')
  let sum = 0
  let shouldDouble = false
  for (let i = cleanedCardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cleanedCardNumber.charAt(i), 10)

    if (shouldDouble) {
      if ((digit *= 2) > 9) digit -= 9
    }

    sum += digit
    shouldDouble = !shouldDouble
  }

  if (sum % 10 === 0) return true
  return i18n.t('validations.invalid_card_number')
}

export function validCardExpirationDate(value) {
  const expiry = value.replace(/\D/g, '')
  let month = parseInt(expiry.substring(0, 2), 10)
  let year = parseInt(expiry.substring(2, 7), 10)

  if (month < 1 || month > 12) {
    return i18n.t('validations.invalid_month')
  }

  let currentYear = new Date().getFullYear()
  if (year < currentYear) {
    return i18n.t('validations.invalid_year')
  }
  if (year === currentYear && month < new Date().getMonth() + 1) {
    return i18n.t('validations.month_previous_than_current')
  }

  return true
}
