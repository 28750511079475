<template>
  <div class="d-flex flex-column df-input">
    <h3 v-if="label">{{ label }}<span v-if="hasRequiredRule">*</span></h3>
    <v-text-field
      data-cy="text-field"
      v-model="currentValue"
      dense
      flat
      hide-spin-buttons
      outlined
      solo
      :background-color="backgroundColor"
      :disabled="disabled"
      :error-messages="errorMessage"
      :loading="loading"
      :maxlength="maxLength"
      :placeholder="placeholder"
      :rules="rules"
      :suffix="suffix"
      :type="type"
      @keydown="validate"
    >
      <template #prepend-inner>
        <font-awesome-icon
          v-if="leftIcon"
          class="prepend-inner__icon"
          :icon="leftIcon"
        />
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'DfInput',

  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    label: {
      default: '',
      type: String,
    },
    leftIcon: {
      default: '',
      type: String,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    maxLength: {
      default: null,
      type: Number,
    },
    placeholder: {
      default: '',
      type: String,
    },
    rules: {
      default: () => [],
      type: Array,
    },
    suffix: {
      default: '',
      type: String,
    },
    type: {
      default: null,
      type: String,
      validator(value) {
        return ['number'].includes(value)
      },
    },
    value: {
      default: null,
      type: [Number, String, null],
    },
  },

  data() {
    return {
      errorMessage: null,
      hasRequiredRule: false,
    }
  },

  created() {
    this.rules.some((rule) => {
      if (rule.name === 'required') this.hasRequiredRule = true
    })
  },

  computed: {
    backgroundColor() {
      return this.disabled ? '#e6e9e6' : '#f8f9f8'
    },
    currentValue: {
      get() {
        return this.value
      },
      set(newValue) {
        if (this.type === 'number' && !this.isAlphabet(newValue)) {
          return
        }
        this.$emit('input', newValue)
        this.$emit('change', newValue)
        return newValue
      },
    },
  },

  methods: {
    isAlphabet(value) {
      // Checking if keyCode value is one of those, because they should be allowed for numbers
      const eraseTabValue = 8
      const zeroValue = 48
      const dotValue = 190
      return (
        value !== zeroValue && value !== dotValue && value !== eraseTabValue
      )
    },
    validate(currValue) {
      if (this.type === 'number') {
        if (Number(currValue.key) || !this.isAlphabet(currValue.keyCode)) {
          this.errorMessage = null
        } else {
          this.errorMessage = this.$t('Components.input.numbers_only')
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.df-input {
  gap: 8px;

  h3 {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    span {
      color: #f23528;
    }
  }
  .prepend-inner__icon {
    color: #5b6459;
    margin-right: 8px;
    padding: 0px;
  }
}
// placeholder
::v-deep .v-text-field__slot input::placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #5b6459;
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  top: 0px !important;
}
::v-deep .v-input--is-disabled .v-text-field__slot input::placeholder,
::v-deep .v-input--is-disabled .v-text-field__slot input,
::v-deep .v-input--is-disabled .v-text-field__suffix {
  color: #aab2a9;
}
::v-deep .v-input__slot {
  padding: 12px 16px !important;
  margin-bottom: 0px !important;
}
::v-deep .v-input--is-focused fieldset {
  border-color: #39af49;
}
// typed text
::v-deep .v-text-field__slot input {
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0px;
}
// suffix
::v-deep .v-text-field__suffix {
  color: #5b6459;
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
// error messages
::v-deep .v-text-field__details {
  position: absolute;
  top: 46px;
  padding: 0px !important;

  .v-messages__message {
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
}
</style>
